import {IEntity} from "../entities";
import {Tables} from "../tables";
import {fieldType, IField} from "../fields";
import {IEntityTableCol} from "../tableColumns";
import {ICard} from "../cardControls";
import {FIELD_JUSTIFICATION} from "../fieldControls";
import {IColumnCondition} from "../../../UIComponents/QueryBuilder/QueryBuilder";
import {E_ENTITIES} from "../entityDefinitions";
import PhoneContactsCopier from "../../../pages/contacts/PhoneContactsCopier";
import QueryToMailchimp from "../../../pages/contacts/QueryToMailchimp";

export enum EContactFields {
    id, create_date,update_date,
    first_name, last_name, phone_number, email_address, city_id,
    membership_status, residency, custom_fields
}

export const contactFields:{[key in EContactFields]: IField} = {
    [EContactFields.id]: {
        field: "id",
        defaultVal: null,
        type: fieldType.textType,
        title: "id",
        dir: FIELD_JUSTIFICATION.LTR
    },
    [EContactFields.create_date]: {
        field: "create_date",
        defaultVal: () => { return (new Date()).toISOString() },
        type: fieldType.dateType,
        title: "תאריך יצירה",
        dir: FIELD_JUSTIFICATION.LTR
    },
    [EContactFields.update_date]: {
        field: "update_date",
        defaultVal: () => { return (new Date()).toISOString() },
        type: fieldType.dateType,
        title: "תאריך עדכון",
        dir: FIELD_JUSTIFICATION.LTR
    },
    [EContactFields.first_name]: {
        field: "first_name",
        defaultVal: "",
        type: fieldType.textType,
        title: "שם פרטי",
        dir: FIELD_JUSTIFICATION.AUTO_RTL
    },
    [EContactFields.last_name]: {
        field: "last_name",
        defaultVal: null,
        type: fieldType.textType,
        title: "שם משפחה",
        dir: FIELD_JUSTIFICATION.AUTO_RTL
    },
    [EContactFields.phone_number]: {
        field: "phone_number",
        defaultVal: null,
        type: fieldType.textType,
        title: "מספר טלפון",
        dir: FIELD_JUSTIFICATION.LTR
    },
    [EContactFields.email_address]: {
        field: "email_address",
        defaultVal: null,
        type: fieldType.textType,
        title: "כתובת אימייל",
        dir: FIELD_JUSTIFICATION.LTR
    },
    [EContactFields.city_id]: {
        field: "city_id",
        defaultVal: null,
        type: fieldType.entityType,
        options: {entity: E_ENTITIES.cities},
        title: "עיר מגורים",
        dir: FIELD_JUSTIFICATION.AUTO
    },
    [EContactFields.residency]: {
        field: "residency",
        defaultVal: null,
        type: fieldType.textType,
        title: "עיר מגורים",
        dir: FIELD_JUSTIFICATION.AUTO
    },
    [EContactFields.membership_status]: {
        field: "membership_status",
        defaultVal: null,
        type: fieldType.dropdown,
        title: "סטטוס חברות בתנועה",
        dir: FIELD_JUSTIFICATION.AUTO,
        options: [
            {
                value: "active",
                text: "active"
            },
            {
                value: "cancelled",
                text: "cancelled"
            },
            {
                value: "",
                text: "none"
            },
        ]
    },
    [EContactFields.custom_fields]: {
        field: "custom_fields",
        defaultVal: {},
        type: fieldType.contactCustomFields,
        title: "שדות נוספים",
        dir: FIELD_JUSTIFICATION.AUTO_RTL
    },
};

export const ContactBrowserCols:IEntityTableCol[] = [
    {
        field: contactFields[EContactFields.first_name],
        readonly: true,
        defaultWidth: 5,
    },
    {
        field: contactFields[EContactFields.last_name],
        readonly: true,
        defaultWidth: 5,
    },
    {
        field: contactFields[EContactFields.phone_number],
        readonly: true,
        defaultWidth: 10,
    },
    {
        field: contactFields[EContactFields.email_address],
        readonly: true,
        defaultWidth: 15,
    },
    {
        field: contactFields[EContactFields.residency],
        readonly: true,
        defaultWidth: 5,
    },
]

export const ContactCard:ICard = {
    sections: [
        {
            compact: true,
            hideOnNew: true,
            fields: [
                {
                    field: contactFields[EContactFields.id],
                    readonly: true
                },
                {
                    field: contactFields[EContactFields.create_date],
                    readonly: true
                },
                {
                    field: contactFields[EContactFields.update_date],
                    readonly: true
                }
            ]
        },
        {
            fields: [
                {field: contactFields[EContactFields.first_name]},
                {field: contactFields[EContactFields.last_name]},
                {field: contactFields[EContactFields.phone_number]},
                {field: contactFields[EContactFields.email_address]},
                {field: contactFields[EContactFields.city_id]},
                {field: contactFields[EContactFields.membership_status]},
            ]
        },
        {
            fields: [
                {field: contactFields[EContactFields.custom_fields]},
            ]
        }
    ],
    relatedEntities: [
        {
            title: "השתתפות בפעילות",
            entity: E_ENTITIES.contactActivities,
            query: (id:number)=> {
                return {
                    "conditions": [({
                        "filter": "f0",
                        "id": "f1",
                        "l": 0,
                        "s": false,
                        "subject": "j_participants",
                        "t": 1,
                        "value": {
                            "innerQuery": {
                                "conditions": [{
                                    "filter": "co_in",
                                    "id": "f2",
                                    "l": 0,
                                    "s": false,
                                    "subject": "c_id",
                                    "t": 1,
                                    "value": [id]
                                }], "table": Tables[Tables.DB_TABLE_CONTACT]
                            }, "join": "participants"
                        }
                    } as IColumnCondition)], 
                    "table": Tables[Tables.DB_TABLE_ACTIVITY],
                    "extraColumns": {
                        "max(data_source_contacts_1.create_date)" : "participation_date"
                    }
                }
            }
        },
        {
            title: "קבוצות",
            entity: E_ENTITIES.contactGroups,
            query: (id)=>{
                return { "conditions": [({
                    "filter": "f0",
                    "id": "f1",
                    "l": 0,
                    "s": false,
                    "subject": "j_includes_member",
                    "t": 1,
                    "value": {
                        "innerQuery": {
                            "conditions": [
                                {
                                    "filter": "co_in",
                                    "id": "f2",
                                    "l": 0,
                                    "s": false,
                                    "subject": "c_id",
                                    "t": 1,
                                    "value": [ id ]
                                }
                            ],
                            "table": Tables[Tables.DB_TABLE_CONTACT]
                        },
                        "join": "includes_member"
                    }
                } as IColumnCondition)], "table": Tables[Tables.DB_TABLE_CONTACT_GROUP]}
            }
        }
    ]
}

export interface IContact {
    id: number | null,
    create_date: Date | null,
    update_data: Date | null,
    first_name: string | null,
    last_name: string | null,
    phone_number: string | null,
    email_address: string | null,
    membership_status: membershipStatus | null,
    city_id: number | null,
    residency?: string | null,
    custom_fields: { [key:string] : any },
}

export enum membershipStatus {
    'active',
    'cancelled',
    null
}

export const ContactEntity:IEntity = {
    browsingPage: {
        suffix: "contacts",
        title: "אנשי קשר",
        newItemLabel: "איש קשר חדש"
    },
    cardPage: {
        suffix: "contact",
        title: "איש קשר",
        titleFromData: (c:IContact)=>{
            return <span style={{display: "flex", alignItems: "center"}}>
                <span>{(c.first_name + " " + c.last_name || "").trim()}</span>
                <span>&nbsp;&nbsp;&nbsp;</span>
                <span style={{fontWeight: 100, fontSize: "0.7em"}}>
                    ({[c.phone_number, c.email_address]
                    .filter(k=>!!k)
                    .map(k=>"🔑 "+k)
                    .join(", ")})
                </span>
            </span>
        }
    },
    endpoint: "contacts",
    fields: Object.values(contactFields),
    table: Tables.DB_TABLE_CONTACT,
    tableColumns: ContactBrowserCols,
    cardLayout: ContactCard,
    browserActions: [
        (query, searchTerm)=><PhoneContactsCopier query={query} searchTerm={searchTerm}/>,
        (query, searchTerm)=><QueryToMailchimp query={query} searchTerm={searchTerm}/>,
    ]
}

