import React from 'react';
import server from '../services/server';
import './Landing/Landing.scss';
import withRouter from "../services/withRouter"
import logo from "../static/logo_purple.svg"

const ErrorMessages = {
	NoFirstName: 'יש להזין שם פרטי يرجى اضافة الاسم للشخصي',
	NoLastName: 'יש להזין שם משפחה يرجى اضافة اسم العائلة',
	NoEmailOrPhone: `יש להזין מספר טלפון תקין או כתובת אימייל תקינה
يرجى اضافة رقم هاتف من صحيح او بريد الكتروني صحيح`,
	InvalidEmail: 'יש להזין כתובת אימייל תקינה يرجى اضافة بريد الكتروني صحيح',
	InvalidPhone: 'יש להזין מספר טלפון תקין يرجى اضافة رقم هاتف من صحيح',
	NoResidency: 'יש להזין יישוב מגורים תקין يرجى اضافة مكان السكنى صحيح',
	general: 'הייתה שגיאה במילוי הטופס, אנא בדקו את הפרטים ונסו שנית'
}

const FieldPatterns = {
	firstName: /^\D{2,}$/,
	lastName: /^\D{2,}$/,
	residency: /^.{2,}$/,
	email: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
	// email: /^\D{2,}$/,
	phone: /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{1,3})?[-. )]*(\d{2,3})[-. ]*(\d{1,4})(?:\s*(\d+))?[-. ]*(\d{2,4})\s*$/,
}

export default (withRouter(class Login extends React.Component {
	constructor(props) {
		super(props);
		const hashProp = this.props.searchParams.get("id");
		this.state = {
			hash: hashProp,
			activity: null,
			success: false
		};
		this.validateAndSendForm = this.validateAndSendForm.bind(this);
        this.dialogRef = React.createRef();
		this.openDiscliamerDialog = this.openDiscliamerDialog.bind(this);
		this.closeDiscliamerDialog = this.closeDiscliamerDialog.bind(this);
	}

	getEvent(hash) {
		return server.get(`./activities/landing-data/${hash}`)
		.then(res => {
			if (!res) return
			this.setState({ activity: res })
		})
	}

	componentDidMount() {
		this.getEvent(this.state.hash)
	}

	customPhoneValidate(phone) {
		return phone.match(/\d/g).length >= 10
	}

	validateAndSendForm(e) {
		this.setState({ errorKey: ''});
		if (!this.state.firstName || !FieldPatterns.firstName.test(this.state.firstName)) {
			this.setState({ errorKey: 'NoFirstName'});
			document.getElementById('firstName').focus()
			return
		}
		if (!this.state.lastName || !FieldPatterns.lastName.test(this.state.lastName)) {
			this.setState({ errorKey: 'NoLastName'});
			document.getElementById('lastName').focus()
			return
		}
		if (!this.state.email && !this.state.phone) {
			this.setState({ errorKey: 'NoEmailOrPhone'});
			document.getElementById('phone').focus()
			return
		}
		if (this.state.email && !FieldPatterns.email.test(this.state.email)) {
			this.setState({ errorKey: 'InvalidEmail'});
			document.getElementById('email').focus()
			return
		}
		if (this.state.phone && (!FieldPatterns.phone.test(this.state.phone) || !this.customPhoneValidate(this.state.phone))) {
			this.setState({ errorKey: 'InvalidPhone'});
			document.getElementById('phone').focus()
			return
		}
		if (!this.state.residency || !FieldPatterns.residency.test(this.state.residency)) {
			this.setState({ errorKey: 'NoResidency'});
			document.getElementById('residency').focus()
			return
		}
		
		this.setState({ isLoading: true })

		const formSubmitRequest = new Request(`/api/contacts/landing-page-form`, 
		{
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				hash: this.state.hash,
				firstName: this.state.firstName,
				lastName: this.state.lastName,
				email: this.state.email,
				phone: this.state.phone,
				residency: this.state.residency
			})
		})
		fetch(formSubmitRequest)
		.then(res => res.json())
		.then(async res => {
			if (res.error) throw res;
			if (res.ok === false) throw res;
			
			this.setState({ 
				firstName: '',
				lastName: '',
				email: '',
				phone: '',
				residency: '',
				errorKey: '',
				isLoading: false,
				success: true
			});
			document.querySelectorAll('.form input').forEach(elm => elm.value = '')
			setTimeout(() => {
				this.setState({ success: false })
			}, 5000);
		})
		.catch(async err => {
			console.log('caught the error');
			console.log(err);
			
			if (err.errorKey) this.setState({ errorKey: err.errorKey })
			else this.setState({ errorKey: 'general '})
		})
	}

	formInputChange(event, newState) {
		newState.errorMsg = '';
		this.setState(newState);
	}

	formInputKeyDown(event) {
		if (event.keyCode === 13) {
			const parentLabel = event.target.parentElement
			const nextElement = parentLabel.nextElementSibling
			if (nextElement.nodeName === 'LABEL') {
				nextElement.children[0].focus()
			} else if (nextElement.nodeName === 'BUTTON') {
				nextElement.focus()
				this.validateAndSendForm()
			}
		}
	}

	isErrorKey(errorKey, errorKey2) {
		return this.state.errorKey === errorKey || this.state.errorKey2 === errorKey
	}

	putDateInHeadline() {
		const activityIdsWithDateInHeadline = [
			'ecde81f8c921e666c53af66ca1accf5e',  // Haifa protest
			'3ed65f73c47b4ecdf8b72d9a9d45d255',  // Tel Aviv Jaffa protest
			'83d3f8a0a9f4eb7c12e52a703705af1f',  // Jerusalem protest
			'6317bd14f6aa7315e411d04ec61a6503',  // Kfar Saba protest
			'b7cde185185dde52f9ae858f949cb2b5',  // Beer Seba protest
		]
		return activityIdsWithDateInHeadline.includes(this.state.hash)
	}

	getDateText() {
		const dateObj = new Date()
		const curr_date = String(dateObj.getDate()).padStart(2, '0');
		const curr_month = String(dateObj.getMonth() + 1).padStart(2, '0'); //Months are zero based
		const curr_year = dateObj.getFullYear();
		return `${curr_date}.${curr_month}.${curr_year}`;
	}

	closeDiscliamerDialog() {
		this.dialogRef.current.close()
	}
	openDiscliamerDialog() {
		this.dialogRef.current.showModal()
		function closeModal() {
			document.querySelector('dialog').close()
		}
		setTimeout(_ => document.addEventListener('click', closeModal, { once: true }), 10)
		this.dialogRef.current.addEventListener('close', _ => {
			document.removeEventListener('click', closeModal)
		})
	}

	render() {
		if (this.state.activity) {
			return <div className="landing-page">
				<img
					alt={"logo"}
					src={logo}
					className={"logo"}
				/>
				<h1>{ this.state.activity.title }{ this.putDateInHeadline()? ' | '+this.getDateText():null}</h1>
				{/* <p>
					עומדים ביחד היא תנועה שנאבקת למען עתיד בטוח בארץ, למען שיטה שסופרת את כולנו, למען חיים בכבוד, בצדק חברתי ובשלום. כי למרות שאנחנו באים ממקומות שונים, למרות שיש לנו זהויות שונות - האינטרסים שלנו משותפים. זה נכון לכולנו, ערבים ויהודים, וזה הופך אותנו לרוב. בעומדים ביחד אנחנו מתארגנים, בונים כוח, ובונים תנועה שתוביל את הדרך לשם. הצטרפו אלינו.
				</p>
				<p>
					نقف معًا هو حراك يناضل من أجل مستقبل آمن في البلاد، من أجل نظام يهمنا جميعًا، من أجل حياة كريمة وعدالة اجتماعية وسلام. لأنه على الرغم من أننا نأتي من أماكن مختلفة، على الرغم من اختلاف هوياتنا - فإن اهتماماتنا مشتركة. هذا صحيح بالنسبة لنا جميعًا كعرب ويهود، وهو يجعلنا أغلبية. في نقف معًا، نتنظم  ونبني القوة ونبني حراكًا سيقود الطريق إلى هناك. انضموا إلينا.
				</p> */}
				<div className="form">
					<label htmlFor="firstName">الاسم الشخصي שם פרטי *
						<input type="text" id="firstName" name="firstName" 
							   className={ this.isErrorKey('NoFirstName')?'error':''} 
							   onChange={e => this.formInputChange(e, {firstName: e.target.value.trim()})}
							   onKeyDown={e => this.formInputKeyDown(e)}>
						</input>
					</label>

					<label htmlFor="lastName">اسم العائلة שם משפחה *
						<input type="text" id="lastName" name="lastName" 
							   className={ this.isErrorKey('NoLastName')?'error':''} 
						       onChange={e => this.formInputChange(e, {lastName: e.target.value.trim()})}
						       onKeyDown={e => this.formInputKeyDown(e)}>
						</input>
					</label>

					<label htmlFor="phone">رقم الهاتف טלפון { !this.state.email? '*' : null }
						<input type="tel" id="phone" name="phone" 
							   className={ this.isErrorKey('NoEmailOrPhone', 'InvalidPhone')?'error':''} 
						       onChange={e => this.formInputChange(e, {phone: e.target.value.trim()})}
						       onKeyDown={e => this.formInputKeyDown(e)}>
						</input>
					</label>

					<label htmlFor="email">البريد الإلكتروني כתובת דוא"ל { !this.state.phone? '*' : null }
						<input type="email" id="email" name="email" land="en"
							   className={ this.isErrorKey('NoEmailOrPhone', 'InvalidEmail')?'error':''} 
						       onChange={e => this.formInputChange(e, {email: e.target.value.trim()})}
						       onKeyDown={e => this.formInputKeyDown(e)}>
						</input>
					</label>

					<label htmlFor="residency">مكان الاقامة יישוב מגורים *
						<input type="text" id="residency" name="residency" 
						       onChange={e => this.formInputChange(e, {residency: e.target.value.trim()})}
						       onKeyDown={e => this.formInputKeyDown(e)}>
						</input>
					</label>

					<button onClick={this.validateAndSendForm}>
						{ this.state.isLoading? 
								<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
									<radialGradient id="a" cx=".7" cy=".3" fx=".7" fy=".3" gradientTransform="scale(2)">
										<stop offset="0" stopColor="#FFCF00"/>
										<stop offset=".3" stopColor="#FFCF00" stopOpacity=".9"/>
										<stop offset=".6" stopColor="#FFCF00" stopOpacity=".6"/>
										<stop offset=".8" stopColor="#FFCF00" stopOpacity=".3"/>
										<stop offset="1" stopColor="#FFCF00" stopOpacity="0"/>
									</radialGradient>
									<circle cx="100" cy="100" r="70" fill="none" stroke="url(#a)" strokeDasharray="200 1000" strokeLinecap="round" strokeWidth="15" transform-origin="center">
										<animateTransform attributeName="transform" calcMode="spline" dur="2" keySplines="0 0 1 1" keyTimes="0;1" repeatCount="indefinite" type="rotate" values="360;0"/>
									</circle>
									<circle cx="100" cy="100" r="70" fill="none" stroke="#FFCF00" strokeLinecap="round" strokeWidth="15" opacity=".2" transform-origin="center"/>
								</svg>
						: 'שליחה ارسال'}
						</button>
				</div>
				<div className="error-msg">
					{ this.state.errorKey? ErrorMessages[this.state.errorKey]:'' }
				</div>
				{ (this.state.success)? <div className='success-msg'>
					תודה! הטופס נשלח בהצלחה
				</div>:null}
				<p className="disclaimer">
					בשליחה אני מסכים.ה ל
					<button  onClick={this.openDiscliamerDialog}>
						תנאי השימוש
					</button>
				</p>
				<dialog ref={this.dialogRef}>
בעצם מסירת פרטיי לתנועת “עומדים יחד” (להלן: “התנועה”) אני מסכים/ה כי המידע האישי שאמסור, לרבות פרטים מזהים כגון שם, כתובת, מספר טלפון ודוא”ל, מידע על עמדותיי, דעותיי הפוליטיות, דפוס הצבעתי והשתתפותי בפעילויות העמותה (כגון סקרים, חתימה על עצומות או פעילות התנדבותית), ייאסף, יישמר ויעובד לצורך ניהול מאגרי המידע, יצירת קשר, עדכונים, קידום מטרות העמותה, וניתוח מידע לשיפור פעילותה. אני מסכים/ה כי אם תרמתי לעמותה והעברתי פרטים אישיים במסגרת זו, פרטים אלו יישמרו גם הם כחלק ממאגר המידע וייעשה בהם שימוש בהתאם לאמור. המידע ישמש גם לדיוור ישיר שיכלול עדכונים מותאמים על פעילויות, יוזמות ופרויקטים של העמותה, באמצעי התקשורת שמסרתי (דוא”ל,SMS , שיחות טלפון, רשתות חברתיות וכדומה). אני מסכים/ה כי המידע עשוי להיות מועבר, במידת הצורך, לגורמים בעלי מטרות דומות למטרות העמותה, וזאת בכפוף להוראות החוק.
<br/>
התנועה מתחייבת לשמור על פרטיותי ולהגן על המידע בהתאם להוראות החוק. ידוע לי כי ייתכן שהמידע יועבר, במידת הצורך בלבד, לספקי שירות חיצוניים כגון שירותי ניתוח נתונים ודיוור, תוך שמירה על סודיותו. כמו כן, אני מודע/ת לזכותי לעיין במידע שנאסף אודותיי, לתקן מידע שגוי או לעדכן פרטים, לדרוש את מחיקת המידע בכפוף לדרישות החוק, או לבטל את הסכמתי לדיוור בכל עת. אני מאשר/ת כי קראתי והבנתי את תנאי כתב ההסכמה ומסכים/ה לשימוש במידע בהתאם לאמור לעיל.
<br/>
<br/>
بمجرّد تقديمي بياناتي إلى حراك “نقف معًا” (المشار إليه فيما يلي بـ “الحراك”)، فإنني أوافق على جمع ومعالجة المعلومات الشخصية التي أقدّمها، بما في ذلك التفاصيل التعريفية مثل الاسم، العنوان، رقم الهاتف والبريد الإلكتروني، المعلومات حول مواقفي، آرائي السياسية، نمط تصويتي ومشاركتي في أنشطة الجمعية (مثل الاستبيانات، توقيع العرائض أو الأنشطة التطوعية)، وذلك لأغراض إدارة قواعد البيانات، التواصل، الحتلنات، تعزيز أهداف الجمعية وتحليل المعلومات لتحسين أنشطتها. كما أوافق على أنه في حال تبرّعتُ للجمعية وقدّمت بيانات شخصية في هذا الإطار، سيتم الاحتفاظ بهذه البيانات كجزء من قاعدة البيانات واستخدامها وفقًا لما ورد أعلاه. وستُستخدم المعلومات أيضًا للإرسال المباشر الذي يتضمن تحديثات مخصّصة حول أنشطة، مبادرات، ومشاريع الجمعية من خلال وسائل الاتصال التي قدّمتها (البريد الإلكتروني، الرسائل النصية، المكالمات الهاتفية، وسائل التواصل الاجتماعي، وما إلى ذلك). كما أوافق على أنه قد يتم نقل المعلومات، عند الضرورة، إلى جهات ذات أهداف مشابهة لأهداف الجمعية، وذلك وفقًا لأحكام القانون.
<br/>
 تتعهد الجمعية بالحفاظ على خصوصيتي وحماية المعلومات وفقًا لأحكام القانون. أعلم أنه قد يتم نقل المعلومات، عند الضرورة فقط، إلى مقدّمي خدمات خارجيين مثل خدمات تحليل البيانات والإرسال، مع ضمان سرّيتها. كما أدرك أن لديّ الحق في الاطلاع على المعلومات التي جُمعت عني، تصحيح المعلومات الخاطئة أو تحديث التفاصيل، طلب حذف المعلومات وفقًا لمتطلبات القانون، أو إلغاء موافقتي على الإرسال في أي وقت. أقرّ بأنني قرأت وفهمت شروط وثيقة الموافقة وأوافق على استخدام المعلومات وفقًا لما ورد أعلاه.


				</dialog>
			</div>
		} else {
			return <div className="landing-page loading">
				Loading...
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
					<radialGradient id="a" cx=".7" cy=".3" fx=".7" fy=".3" gradientTransform="scale(2)">
						<stop offset="0" stopColor="#FFCF00"/>
						<stop offset=".3" stopColor="#FFCF00" stopOpacity=".9"/>
						<stop offset=".6" stopColor="#FFCF00" stopOpacity=".6"/>
						<stop offset=".8" stopColor="#FFCF00" stopOpacity=".3"/>
						<stop offset="1" stopColor="#FFCF00" stopOpacity="0"/>
					</radialGradient>
					<circle cx="100" cy="100" r="70" fill="none" stroke="url(#a)" strokeDasharray="200 1000" strokeLinecap="round" strokeWidth="15" transform-origin="center">
						<animateTransform attributeName="transform" calcMode="spline" dur="2" keySplines="0 0 1 1" keyTimes="0;1" repeatCount="indefinite" type="rotate" values="360;0"/>
					</circle>
					<circle cx="100" cy="100" r="70" fill="none" stroke="#FFCF00" strokeLinecap="round" strokeWidth="15" opacity=".2" transform-origin="center"/>
				</svg>
			</div>
		}
	}
}))

