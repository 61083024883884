export enum E_ENTITIES {
    contacts,
    activities,
    contactActivities,
    activityTypes,
    activityTags,
    customContactFields,
    customActivityFields,
    contactGroups,
    cities,
    dataSources,
    users
}